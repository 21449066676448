const treeTypeMapping = [
  'Bu',
  'TEi',
  'SEi',
  'BAh',
  'SAh',
  'BUl',
  'Es',
  'SEr',
  'TKi',
  'FAh',
  'HBu',
  'Ki',
  'WLi',
  'SLi',
  'EBe',
  'MBe',
  'VBe',
  'Nu',
  'FUl',
  'FEi',
  'FlUl',
  'SbAh',
  'HBi',
  'Ro',
  'REi',
  'As',
  'Ta',
  'Fi',
  'Fö',
  'Lä',
  'Eib',
  'BFö',
  'SFö',
  'Dou',
];

export default treeTypeMapping;
