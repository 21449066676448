(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("@geops/tree-lib", [], factory);
	else if(typeof exports === 'object')
		exports["@geops/tree-lib"] = factory();
	else
		root["@geops/tree-lib"] = factory();
})(window, function() {
return 