import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { get } from 'ol/proj';

proj4.defs(
  'EPSG:2056',
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs',
);

register(proj4);
export const EPSG2056 = get('EPSG:2056');

const projection = {};

export default projection;
