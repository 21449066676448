const vegetationMapping = [
  'a',
  'b',
  'c',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
];

export default vegetationMapping;
