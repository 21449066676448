const reliefMapping = [
  '1,2',
  '6,7',
  '8',
  '9,10,11',
  '12,13',
  '14,15,16',
  '17',
  '18,19,20,21',
  '22,23,24',
  '26,27',
  '28,29,30,32',
  '44,45',
  '46',
  '48',
  '49,50',
  '53,56,57,60',
  '62,65',
  '67,69,70,71',
  'AV',
];

export default reliefMapping;
