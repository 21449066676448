const transitionMapping = {
  '7a(10a)': ['7a(10a)'],
  '7S(10a)': ['7S(10a)'],
  '7a(9a)': ['7a(9a)'],
  '7S(9a)': ['7S(9a)'],
  '8a(12a)': ['8a(12a)'],
  '8a(12e)': ['8a(12e)'],
  '9a(11)': ['9a(11)'],
  '12a(9a)': ['12a(9a)'],
};

export default transitionMapping;
