const reliefMapping = [
  '1,6,7a(10a),7S(10a),9a,9w,10a,12e,14,15,16,18*',
  '7a',
  '7b,7a(9a),7S(9a),8a(12a),8a,8d',
  '7S,9a(11),27,27h,30,44',
  '8a(12e)',
  '8S',
  '11,12S,20,26,26h',
  '12a,12a(9a),12w,13a,13e,13h,13eh,17,18M,19,22,22C,25*',
  '29',
  '35A',
  '38',
  '39',
  '48',
  '61,62',
  '65',
];

export default reliefMapping;
